import React, { ReactElement, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { DanubeTermsAndConditionsComponent } from './pages/EU/DANUBE/termsAndConditions';
import { DanubePrivacyPolicy } from './pages/EU/DANUBE/privacyPolicy';
import { NotFoundPage } from './pages/NotFoundPage';
import {
  EmUSUnsubscribeConfirmation,
  USEmUnsubscribeLayout,
  EmUSUnsubscribeStatus
} from './pages/US/HIC/unsubscribe/email-marketing';
import { DanubeOptOutLoyalty, DanubeUnsubscribeEmailMarketing } from './pages/EU/DANUBE';
import { BroomfieldPrivacyPolicy } from './pages/US/Broomfield/privacyPolicy';
import { BroomfieldLoyaltyPrivacy, BroomfieldLoyaltyTerms } from './pages/US/Broomfield/loaylty';
import { AppContainer } from './styles';
import { useSearchParams } from 'react-router-dom';
import { STATE_STATUS } from 'store/constants';
import { getStatus } from 'store/termsAndConditions/termsAndConditionsSlice';
import { useSelector } from 'react-redux';
import { ErrorPage } from './pages/ErrorPage';

const unsubscribeEmRouteForUS = (
  <Route path="/unsubscribe">
    <Route path="email-marketing" element={<USEmUnsubscribeLayout />}>
      <Route index element={<EmUSUnsubscribeConfirmation />} />
      <Route path="status" element={<EmUSUnsubscribeStatus />} />
    </Route>
  </Route>
);

const composeRoutesViaAccount = (account: string | null): ReactElement => {
  switch (account) {
    case 'mygp.cz':
    case 'mygp.ro':
    case 'mygp.at':
    case 'mygp.sk':
      return (
        <Routes>
          <Route path="/terms-and-conditions" element={<DanubeTermsAndConditionsComponent />} />
          <Route path="/privacy-policy" element={<DanubePrivacyPolicy />} />
          <Route path="/opt-out/loyalty" element={<DanubeOptOutLoyalty />} />
          <Route path="/unsubscribe/email-marketing" element={<DanubeUnsubscribeEmailMarketing />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      );
    case 'apphub-broomfield-us':
      return (
        <Routes>
          <Route path="/terms-and-conditions" element={<NotFoundPage />} />
          <Route path="/privacy-policy" element={<BroomfieldPrivacyPolicy />} />
          <Route path="/privacy" element={<BroomfieldLoyaltyPrivacy />} />
          <Route path="/terms" element={<BroomfieldLoyaltyTerms />} />
          {unsubscribeEmRouteForUS}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      );
    case 'default':
    case 'oe-us':
    case 'hpy.us':
    case 'apphub-propay-us':
      return (
        <Routes>
          {unsubscribeEmRouteForUS}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      );
    default:
      return (
        <Routes>
          <Route path="/terms-and-conditions" element={<NotFoundPage />} />
          <Route path="/privacy-policy" element={<NotFoundPage />} />
          <Route path="/opt-out/loyalty" element={<NotFoundPage />} />
          <Route path="/unsubscribe/email-marketing" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      );
  }
};

function App() {
  const [searchParams] = useSearchParams();
  const account = searchParams.get('account');
  const requestStatus = useSelector(getStatus);
  const isError = useMemo(() => requestStatus === STATE_STATUS.REJECTED, [requestStatus]);

  return isError ? <ErrorPage account={account} /> : <AppContainer>{composeRoutesViaAccount(account)}</AppContainer>;
}

export default App;
